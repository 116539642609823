
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vue3Toasity from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
        
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
        
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'


import 'vuetify/styles'
import "@mdi/font/css/materialdesignicons.css";

const myTheme = {
    dark: true,
    colors: {
        primary: '#3ad38e',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#ba3c3c',
        info: '#099FFF',
        success: '#5fa052',
        warning: '#FF6600',

    },
}
        
const vuetify = createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'myTheme',
        themes: {
            myTheme,
        },
    },
})
const app = createApp(App)
import './assets/main.css'
        
app.config.productionTip = false

import axios from 'axios'
let api = 'http://localhost:5000/'
if (process.env.NODE_ENV === 'production') {
    api = 'https://api.dashboard.shkoysha.com/'
}
axios.defaults.baseURL = api;
axios.defaults.headers.post['Content-Type'] = 'application/json';


app.config.globalProperties.$imagePath = api + 'static/'
app.component('VueDatePicker', VueDatePicker);

import VueApexCharts from "vue3-apexcharts";
app.use(VueApexCharts)


app.use(Vue3Toasity, 
    {
        position: 'top-right',
        duration: 1500,
        hideProgressBar: true,
        closeButton: true,
        pauseOnHover: true,
        icon: true,
        rtl: false,
        transition: 'Vue-Toastify__bounce',
        maxToasts: 3,
        newestOnTop: true
    })
        
app
.use(store)
.use(router)
.use(vuetify)
.mount('#app')        
        